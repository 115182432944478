export function addConnectelChat (country) {
  const se = document.createElement('script')
  se.type = 'text/javascript'
  se.id = 'knowledge-base'
  console.log('country =>  ', country)
  if (country === 'no') { se.src = (document.location.protocol === 'https:' ? 'https://' : 'https://') + 'client.connectel.io:8080/api/chat/websites/12/snippet?token=Q4Ny6uYcgwSlEJ9eHH7P13LvSQT8w9mf' } else { se.src = (document.location.protocol === 'https:' ? 'https://' : 'http://') + 'zmarta.kb.connectel.app/faq/widget.js?uniqueId=a08SYUfmbvCPHZ0JB1sb' }

  const s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(se, s)

  const style = document.createElement('style')
  style.appendChild(document.createTextNode(`
    #connectel-launcher-container628 > div[id^="zoid-connectel-launcher"] {
      z-index: 20 !important;
    }
  `))

  const st1 = document.getElementsByTagName('style')[0]
  st1.parentNode.insertBefore(style, st1)
}
